<!--财务管理-->
<template>
    <div class="account-model">
        <div class="global-view-table">
            <p style="margin-bottom: 10px;">
                注：1、支付通道开启后将立即生效，新借款需要重新验证银行卡，旧订单还款和新绑卡使用优先级高的支付通道；
                <br>
                2、如果自动扣款失败则需用户手动还款一次；
                <br>
                3、数字越小优先级越高
            </p>
            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="ID"/>
                <el-table-column prop="name" label="支付名称"/>
                <el-table-column prop="sort" label="优先级">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.sort" @blur="changeSort(row)" type="number"/>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{row}">
                        <el-tag type="success" v-if="row.status">开启</el-tag>
                        <el-tag type="danger" v-if="!row.status">关闭</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="195">
                    <template slot-scope="{ row }">
                        <el-button size="small" type="primary" v-if="row.isSplit" @click="toDetail(row)">设置分账</el-button>
                        <el-button size="small" v-if="!row.status" type="success" @click="changeStatus(row)">开启通道</el-button>
                        <el-button size="small" v-if="row.status" type="danger" @click="changeStatus(row)">关闭通道</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {getUpsRoute,changeStatus,changeSort} from "@/api/public";

    export default {
        name: "account-list",
        data() {
            return {
                tableLoad: false,
                tableData: [],
            };
        },
        created() {
            this.search();
        },
        methods: {
            toDetail(row){
              this.$router.push({path:'/splitAccounts',query:{id:row.id}})
            },

            changeSort(row){
                changeSort({
                    id:row.id,
                    sort:row.sort
                }).then(()=>{
                    this.$message.success('修改成功')
                    this.search()
                }).catch(()=>{
                    this.search()
                })
            },

            changeStatus(row){
                this.$messageBox.confirm(`确认${row.status?'关闭通道':'开启通道'}, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    changeStatus({
                        id:row.id,
                    }).then(()=>{
                        this.$message.success('修改成功')
                        this.search()
                    }).catch(()=>{
                        this.search()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },

            search() {
                this.tableLoad = true;
                getUpsRoute({})
                    .then((res) => {
                        this.tableData = res.data;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }

    .diy_card {
        min-width: 150px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        //box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    }
</style>
