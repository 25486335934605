<template>
    <div class="global-view-card banner-model">
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="add">创建渠道
                </el-button
                >
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="ID" width="80px"/>
                <el-table-column prop="channelName" label="渠道名称" width="145"/>
                <el-table-column prop="channelCode" label="渠道编码" width="120"/>
                <el-table-column prop="channelUrl" label="渠道链接"/>
                <el-table-column prop="remarks" label="备注" show-overflow-tooltip/>
                <el-table-column prop="createOperatorName" label="添加者" width="145"/>
                <el-table-column prop="createTime" label="添加时间" width="145" sortable/>
                <!--<el-table-column prop="bannerStatus" label="状态" width="120px">-->
                <!--<template slot-scope="{ row }">-->
                <!--<el-switch-->
                <!--v-model="row.status"-->
                <!--:active-value="1"-->
                <!--:inactive-value="0"-->
                <!--@change="changeStatus(row)"-->
                <!--/>-->
                <!--<el-link-->
                <!--:underline="false"-->
                <!--:type="row.status === 1 ? 'primary' : ''"-->
                <!--class="status-label"-->
                <!--&gt;&nbsp;&nbsp;{{-->
                <!--row.status === 1 ? "启用" : "禁用"-->
                <!--}}-->
                <!--</el-link-->
                <!--&gt;-->
                <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column
                        prop="operation"
                        label="操作"
                        width="170px"
                >
                    <template slot-scope="{ row }">
                        <el-button type="primary" @click="copy(row)" v-if="row.channelUrl">复制链接</el-button>
                        <el-button @click="edit(row)"> 修改</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>


        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form" label-width="80px" :rules="rules" ref="ruleForm">
                <el-form-item label="渠道名称" prop="channelName">
                    <el-input v-model="form.channelName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="渠道编码" prop="channelCode">
                    <el-input v-model="form.channelCode" autocomplete="off" :disabled="form.id !== null"></el-input>
                </el-form-item>
                <el-form-item label="渠道备注">
                    <el-input
                            type="textarea"
                            rows="3"
                            maxlength="100"
                            :show-word-limit="true"
                            placeholder="请输入内容"
                            v-model="form.remarks">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {addChannel, getChannelList, updateChannel} from "@/api/public";
    // import { filterFormat } from "@/utils/tools";
    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                dialogFormVisible: false,
                title: '添加推广链接',
                form: {
                    id: null,
                    channelName: null,
                    channelCode: null,
                    remarks: ''
                },
                rules: {
                    channelName: [
                        {required: true, message: '请输入渠道名称', trigger: 'blur'},
                    ],
                    channelCode: [
                        {required: true, message: '请输入渠道Code', trigger: 'blur'},
                    ],
                }
            };
        },
        created() {

        },
        mounted() {
            this.search(this.pageInfo);
        },
        methods: {
            updateChannel(){
                updateChannel(this.form).then(()=>{
                    this.search(this.pageInfo);
                    this.$message.success('编辑成功');
                    this.resetForm('ruleForm')
                })
            },

            addChannel(){
                addChannel(this.form).then(()=>{
                    this.search(this.pageInfo);
                    this.$message.success('添加成功');
                    this.resetForm('ruleForm')
                })
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        !this.form.id?this.addChannel():this.updateChannel();
                    } else {
                        return false;
                    }
                });
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogFormVisible = false;
            },

            add() {
                this.form={
                    id: null,
                    channelName: null,
                    channelCode: null,
                    remarks: ''
                };
                this.title = '添加推广链接';
                this.dialogFormVisible = true;
            },

            edit(row) {
                this.form = {
                    id: row.id,
                    channelName: row.channelName,
                    channelCode: row.channelCode,
                    remarks: row.remarks
                };
                this.title = '编辑';
                this.dialogFormVisible = true;
            },

            copy(row){
                // 创建输入框元素
                let oInput = document.createElement('input');
                // 将想要复制的值
                oInput.value = row.channelUrl;
                // 页面底部追加输入框
                document.body.appendChild(oInput);
                // 选中输入框
                oInput.select();
                // 执行浏览器复制命令
                document.execCommand('Copy');
                // 弹出复制成功信息
                this.$message.success('复制成功');
                // 复制后移除输入框
                oInput.remove();
            },

            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getChannelList({
                    ...this.pageInfo,
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        records.forEach(item => {
                            item.visibleUserGroupDesc = item.visibleUserGroup && item.visibleUserGroup.split(',') || []
                        })
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            },
            changePage(page) {
                this.search(page);
            },
        },
    };
</script>

<style lang="scss">
    .banner-model {
        .global-view-table {
            margin: 0;
        }
        .table-column-icon {
            width: 72px;
            height: 20px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
</style>
