<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">用户详情</div>
        <div class="global-detail-content" style="padding-left: 24px;">
            <p class="content_title">基础信息</p>
            <el-table
                    :data="basicData"
                    border>
                <el-table-column
                        prop="id"
                        label="用户ID"
                        width="80"/>
                <el-table-column
                        prop="phone"
                        label="手机号"/>
                <el-table-column
                        prop="name"
                        label="实名姓名"/>
                <el-table-column
                        prop="sourceChannelCode"
                        label="渠道来源">
                    <template slot-scope="{row}">
                        {{$enumChannelObj(row.sourceChannelCode,channelList)}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="companyName"
                        label="企业名称"/>
                <el-table-column
                        prop="sourceChannelUserId"
                        label="渠道唯一码"/>
                <el-table-column
                        prop="createTime"
                        label="注册时间"
                        width="142"/>
                <el-table-column
                        prop="lastLoginTime"
                        label="最后登录时间"
                        width="142"/>
            </el-table>


            <el-radio-group v-model="tabVal" style="margin-top: 20px;" @change="getTabVal">
                <el-radio-button label="借款记录"></el-radio-button>
                <el-radio-button label="认证信息"></el-radio-button>
                <el-radio-button label="设备信息"></el-radio-button>
                <el-radio-button label="银行卡信息"></el-radio-button>
            </el-radio-group>

            <el-card style="margin-top: 10px;" v-if="tabVal == '借款记录'">
                <el-table v-loading="borrowLoad" border :data="borrowData" size="mini">
                    <el-table-column prop="borrowOrderNo" label="借款编号"/>
                    <el-table-column prop="productName" label="借款产品"/>
                    <el-table-column prop="amount" label="申请金额"/>
                    <el-table-column prop="periods" label="借款时长">
                        <template slot-scope="{row}">
                            <span v-if="row.periods">{{row.periods}}个月（期）</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="借款状态">
                        <template slot-scope="{row}">
                            {{$enumArrayObj(row.state,borrowSateList)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="borrowTime" label="申请时间"/>
                    <el-table-column prop="operation" label="操作" width="130px">
                        <template slot-scope="{ row }">
                            <el-button size="small" @click="toOrderDetail(row)">
                                查看订单详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <GPagination
                        style="float: right;padding: 10px 0;"
                        :total="borrowTotal"
                        :current-page="pageInfo.pageNum"
                        :size="pageInfo.pageSize"
                        @change="getDetail"
                />
            </el-card>

            <el-card style="margin-top: 10px;" v-if="tabVal == '认证信息'">
                <section>
                    <div class="sub_title">基本信息</div>
                    <el-table border :data="authBasicData" size="mini">
                        <el-table-column prop="phone" label="手机号"/>
                        <el-table-column prop="idCardNo" label="身份证号"/>
                        <el-table-column prop="region" label="现居住地"/>
                        <el-table-column prop="address" label="详细地址"/>
                    </el-table>
                </section>

                <section>
                    <div class="sub_title">公司信息</div>
                    <el-table border :data="authCompanyData" size="mini">
                        <el-table-column prop="companyName" label="公司名称"/>
                        <el-table-column prop="companyType" label="类型"/>
                        <el-table-column prop="legalPerson" label="法定代表人"/>
                        <el-table-column prop="addressDetail" label="住所"/>
                        <el-table-column prop="registeredCapital" label="注册资本"/>
                        <el-table-column prop="registerDate" label="成立日期"/>
                        <el-table-column prop="expiryDateRaw" label="营业期限"/>
                    </el-table>
                </section>

                <section>
                    <div class="sub_title">联系人信息</div>
                    <el-table border :data="authContactData" size="mini">
                        <el-table-column prop="contactName" label="姓名"/>
                        <el-table-column prop="relation" label="关系">
                            <template slot-scope="{row}">
                                {{$enumArrayObj(row.relation,relationList)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="contactPhone" label="移动电话"/>
                    </el-table>
                </section>

                <section>
                    <div class="sub_title">身份证信息(OCR)</div>
                    <el-table border :data="authIdCardData" size="mini">
                        <el-table-column prop="name" label="姓名"/>
                        <el-table-column prop="gender" label="性别">
                            <template slot-scope="{row}">
                                {{$enumArrayObj(row.gender,genderList)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="birthday" label="出生"/>
                        <el-table-column prop="nationality" label="民族"/>
                        <el-table-column prop="idCardNo" label="身份证号"/>
                        <el-table-column prop="address" label="住址" width="230" show-overflow-tooltip/>
                        <el-table-column prop="authority" label="签发机关"/>
                        <el-table-column prop="expiryDate" label="有效期" width="230"/>
                    </el-table>
                </section>

                <section>
                    <div class="sub_title">人脸&身份证&营业执照</div>
                    <div class="sub_content">
                        <div class="sub_item">
                            <div class="sub_label">人脸识别</div>
                            <div class="sub_val">
                                <el-image
                                        style="width: 100px; height: 90px;margin-left: 10px;"
                                        :src="authImg.faceUrl"
                                        :preview-src-list="[authImg.faceUrl]">
                                </el-image>
                            </div>
                        </div>
                        <div class="sub_item" style="width: 50%;">
                            <div class="sub_label">身份证</div>
                            <div class="sub_val">
                                <el-image
                                        style="width: 100px; height: 90px;margin-left: 10px;"
                                        :src="authImg.idCardFrontUrl"
                                        :preview-src-list="[authImg.idCardFrontUrl]">
                                </el-image>
                                <el-image
                                        style="width: 100px; height: 90px;margin-left: 10px;"
                                        :src="authImg.idCardBackUrl"
                                        :preview-src-list="[authImg.idCardBackUrl]">
                                </el-image>
                            </div>
                        </div>

                        <!--<div class="sub_item">-->
                        <!--<div class="sub_label">营业执照</div>-->
                        <!--<div class="sub_val">-->
                        <!--<el-image-->
                        <!--style="width: 100px; height: 90px;margin-left: 10px;"-->
                        <!--:src="url"-->
                        <!--:preview-src-list="srcList">-->
                        <!--</el-image>-->
                        <!--</div>-->
                        <!--</div>-->
                    </div>
                </section>
            </el-card>

            <el-card style="margin-top: 10px;" v-if="tabVal == '设备信息'">
                <el-table v-loading="deviceLoad" border :data="deviceData" size="mini">
                    <el-table-column prop="deviceWidth" label="手机分辨率（宽）"/>
                    <el-table-column prop="deviceHeight" label="手机分辨率（高）"/>
                    <el-table-column prop="osName" label="系统类型"/>
                </el-table>

                <GPagination
                        style="float: right;padding: 10px 0;"
                        :total="deviceTotal"
                        :current-page="devicePageInfo.pageNum"
                        :size="devicePageInfo.pageSize"
                        @change="getBasicUserDeviceList"
                />
            </el-card>

            <el-card style="margin-top: 10px;" v-if="tabVal == '银行卡信息'">
                <el-table v-loading="bankLoad" border :data="bankData" size="mini">
                    <el-table-column prop="id" label="ID"/>
                    <el-table-column prop="bankName" label="所属银行"/>
                    <el-table-column prop="bankCardNo" label="卡号"/>
                    <el-table-column prop="userName" label="持卡人"/>
                    <el-table-column prop="reservedMobile" label="预留手机号"/>
                </el-table>
            </el-card>
        </div>
    </div>
</template>

<script>
    import {
        getEnumByKey,
        getBasicUserDetail,
        getBasicUserDeviceList,
        getUserBorrowOrderList,
        getUserBankCardList,
        getUserAuthInfo,
        allChannelList
    } from "@/api/public";

    import GPagination from "@/components/GPagination";

    export default {
        components: {
            GPagination,
        },
        data() {
            return {
                pageLoad: false,
                pageId: this.$route.query.id || null,
                basicData: [],
                tabVal: '借款记录',
                borrowTotal: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                borrowSateList: [],
                borrowData: [],
                borrowLoad: false,
                authBasicData: [],
                authCompanyData: [],
                authContactData: [],
                relationList: [],
                authIdCardData: [],
                genderList: [],
                authImg: {},
                deviceData: [],
                deviceLoad: false,
                deviceTotal: 0,
                devicePageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                bankData: [],
                bankLoad: false,
                stateList: [],
                channelList:[]
            };
        },
        created() {
            // this.init();
            this.initEnum()
            this.pageId && this.init();
        },
        methods: {
            toOrderDetail(row) {
                this.$router.push({path: '/orderDetail', query: {id: row.id, borrowOrderNo: row.borrowOrderNo}})
            },

            initEnum() {
                this.getEnumByKey('borrowSateList', 'LOAN_ORDER_STATE')
                this.getEnumByKey('relationList', 'USER_CONTACT_RELATION')
                this.getEnumByKey('genderList', 'GENDER');
                this.allChannelList();//获取所有渠道
            },

            allChannelList(){
                allChannelList({}).then((res)=>{
                    this.channelList = res.data;
                })
            },
            // 获取枚举
            getEnumByKey(type, val) {
                getEnumByKey({key: val}).then((res) => {
                    this[type] = res.data.getEnumResponseList;
                })
            },
            getTabVal(val) {
                switch (val) {
                    case '借款记录':
                        this.getUserBorrowOrderList();
                        break;
                    case '认证信息':
                        this.getUserAuthInfo();
                        break;
                    case '设备信息':
                        this.getBasicUserDeviceList(this.devicePageInfo)
                        break;
                    case '银行卡信息':
                        this.getUserBankCardList()
                        break;
                }
            },

            init() {
                this.getDetail(this.pageInfo);
                this.getUserBorrowOrderList()
            },

            getDetail(page) {
                this.pageInfo = page;
                this.pageLoad = true;
                getBasicUserDetail({id: this.pageId, ...this.pageInfo})
                    .then((res) => {
                        this.basicData = [res.data]
                        this.pageLoad = false;
                    }).catch(() => {
                    this.pageLoad = false;
                });
            },


            //用户设备信息
            getBasicUserDeviceList(page) {
                this.devicePageInfo = page;
                this.deviceLoad = true;
                getBasicUserDeviceList({id: this.pageId, ...this.devicePageInfo})
                    .then((res) => {
                        this.deviceTotal = res.data.total;
                        this.deviceData = res.data.records;
                        this.deviceLoad = false;
                    }).catch(() => {
                    this.deviceLoad = false;
                });
            },

            //用户借款记录
            getUserBorrowOrderList() {
                this.borrowLoad = true;
                getUserBorrowOrderList({id: this.pageId})
                    .then((res) => {
                        this.borrowTotal = res.data.total;
                        this.borrowData = res.data.records;
                        this.borrowLoad = false;
                    }).catch(() => {
                    this.borrowLoad = false;

                });
            },


            //用户银行卡信息
            getUserBankCardList() {
                this.bankLoad = true;
                getUserBankCardList({id: this.pageId})
                    .then((res) => {
                        this.bankData = res.data;
                        this.bankLoad = false;
                    }).catch(() => {
                    this.bankLoad = false;

                });
            },

            //用户认证信息
            getUserAuthInfo() {
                getUserAuthInfo({id: this.pageId})
                    .then((res) => {
                        this.authBasicData = res.data.baseInfo ? [res.data.baseInfo] : [];
                        this.authCompanyData = res.data.companyInfo ? [res.data.companyInfo] : [];
                        this.authContactData = res.data.userContactList || [];
                        this.authIdCardData = res.data.identify ? [res.data.identify] : [];
                        this.authImg = {
                            idCardFrontUrl: res.data.idCardFrontUrl,
                            idCardBackUrl: res.data.idCardBackUrl,
                            faceUrl: res.data.faceUrl
                        };
                    }).catch(() => {

                });
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .content_title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 10px;
    }

    .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 10px 0;
    }

    .sub_content {
        display: flex;
        align-items: center;
        border: 1px solid #EBEEF5;
        background-color: #FFF;

        .sub_item {
            display: flex;
            width: 25%;
        }

        .sub_label {
            height: 100px;
            padding: 0 10px;
            font-weight: 600;
            font-size: 12px;
            color: #909399;
            background: #f5f7fa;
            line-height: 100px;
        }

        .sub_val {
            height: 100px;
            display: flex;
            align-items: center;
        }
    }


</style>
