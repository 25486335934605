<!--银行管理-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo" label-width="auto">
                <el-form-item label="银行编号">
                    <el-input
                            v-model="filterInfo.bankCode"
                            placeholder="银行编号"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="银行名称">
                    <el-input
                            v-model="filterInfo.bankName"
                            placeholder="银行名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>

        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="openBank">添加银行</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="bankCode" label="银行编号" width="80px"/>
                <el-table-column prop="bankName" label="银行名称"/>
                <el-table-column prop="bankImg" label="银行图标">
                    <template slot-scope="{row}">
                        <img :src="row.bankImg" style="width: 30px;height:30px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="country" label="国家编码"/>
                <el-table-column prop="bankUkId" label="银行唯一标志"/>
                <el-table-column prop="country" label="状态">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeStatus(row)"
                        />
                        <el-link
                                :underline="false"
                                :type="row.status === 1 ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status === 1 ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="145">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            编辑
                        </el-button>
                        <el-button size="small" type="danger" @click="del(row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>


        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="form" :rules="rules" label-width="auto">
                <el-form-item label="银行编码" prop="bankCode">
                    <el-input v-model="form.bankCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行唯一标志" prop="bankUkId">
                    <el-input v-model="form.bankUkId" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行名称" prop="bankName">
                    <el-input v-model="form.bankName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行图标" prop="bankImg">
                    <GUploadImg
                            v-model="form.bankImg"
                            :customSize="{ width: 98, height: 98 }"
                            :fileSize="300"
                            :entityNo="'bank'"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitBank('form')">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import GUploadImg from "@/components/GUploadImg";
    import {
        getEnumByKey,
        getBankList,
        deleteBank,
        changeBankStatus,
        addBank,
        updateBank
    } from "@/api/public";
    import {filterFormat} from "@/utils/tools";

    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
            GUploadImg
        },
        data() {
            return {
                channelSource: [],
                filterInfo: {
                    bankCode: null,
                    bankName: null,
                },

                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                form: {
                    bankCode: null,
                    bankImg: null,
                    bankName: null,
                    bankUkId: null
                },
                subLoading: false,
                dialogFormVisible: false,
                rules: {
                    bankCode: [{required: true, message: '请选输入银行编码'}],
                    bankName: [{required: true, message: '请输入银行名称'}],
                    bankUkId: [{required: true, message: '请选输入银行唯一标志'}],
                    bankImg: [{required: true, message: '请上传银行图标'}],
                },
                title: "添加银行"
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            openBank() {
                this.title = "添加银行";
                this.form = {
                    bankCode: null,
                    bankImg: null,
                    bankName: null,
                    bankUkId: null
                };
                this.dialogFormVisible = true;
            },

            submitBank(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.subLoading = true;
                        if (this.title == '添加银行') {
                            this.addBank()
                        } else {
                            this.updateBank()
                        }
                    } else {
                        return false;
                    }
                });
            },

            addBank() {
                addBank({
                    ...this.form
                }).then(() => {
                    this.subLoading = false;
                    this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                    this.dialogFormVisible = false;

                }).catch(() => {
                    this.subLoading = false
                })
            },
            updateBank() {
                updateBank({
                    ...this.form
                }).then(() => {
                    this.subLoading = false;
                    this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                    this.dialogFormVisible = false;

                }).catch(() => {
                    this.subLoading = false
                })
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        deleteBank({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changeStatus(row) {
                changeBankStatus({
                    id: row.id,
                    status: row.status,
                }).then(() => {
                    this.$message.success("修改成功");
                    this.search(this.pageInfo);
                });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.title = "编辑银行";
                this.form = {
                    bankCode: row.bankCode,
                    bankImg: row.bankImg,
                    bankName: row.bankName,
                    bankUkId: row.bankUkId,
                    id: row.id
                };
                this.dialogFormVisible = true;
            },

            // 获取枚举
            getEnumByKey(type, val) {
                getEnumByKey({key: val}).then((res) => {
                    this[type] = res.data.getEnumResponseList;
                })
            },

            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getBankList({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
