<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="120px"
                    label-suffix=":"
            >
                <el-form-item label="产品名称" prop="productName">
                    <el-input
                            v-model="detailForm.productName"
                            placeholder="请输入产品名称"
                            :maxlength="64"
                            show-word-limit
                    />
                </el-form-item>

                <el-form-item label="产品额度" prop="defaultAmount">
                    <el-input
                            v-model="detailForm.defaultAmount"
                            placeholder="请输入产品额度"
                            maxlength="6"
                            @input="limitThree"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">元</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：仅作为未认证时显示</p>
                </el-form-item>

                <el-form-item label="日利率" prop="defaultDayRate">
                    <el-input
                            v-model="detailForm.defaultDayRate"
                            type="number"
                            placeholder="请输入日利率"
                            @input="(e)=>{limitFourPoint(e,'defaultDayRate')}"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%/日</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：仅作为未认证时显示</p>
                </el-form-item>

                <el-form-item label="逾期利率" prop="defaultOverdueRate">
                    <el-input
                            v-model="detailForm.defaultOverdueRate"
                            type="number"
                            @input="(e)=>{limitFourPoint(e,'defaultOverdueRate')}"
                            placeholder="请输入逾期利率"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%/日</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：逾期后的利率</p>
                </el-form-item>

                <el-form-item label="逾期费率" prop="defaultOverduePunishRate">
                    <el-input
                            v-model="detailForm.defaultOverduePunishRate"
                            type="number"
                            @input="(e)=>{limitFourPoint(e,'defaultOverduePunishRate')}"
                            placeholder="请输入逾期费率"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%/日</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：逾期费率</p>
                </el-form-item>
                <el-form-item label="产品链接" >
                    <el-input
                            v-model="detailForm.productLink"
                            placeholder="请输入产品链接"
                   />
                </el-form-item>
                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save"
                    >保 存
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getProductDetail, updateProduct, addProduct
    } from "@/api/public";

    export default {

        data() {
            return {
                pageLoad: false,
                title: "",

                detailForm: {
                    defaultAmount: null,
                    defaultDayRate: null,
                    defaultOverduePunishRate: null,
                    defaultOverdueRate: null,
                    id: this.$route.query.id || null,
                    productLink: null,
                    productName: null
                },
                detailRules: {
                    productName: [{required: true, message: "请输入产品名称"}],
                    defaultAmount: [{required: true, message: "请输入产品额度"}],
                    defaultDayRate: [{required: true, message: "请输入日利率"}],
                    defaultOverdueRate: [{required: true, message: "请输入逾期利率"}],
                    defaultOverduePunishRate: [{required: true, message: "请输入逾期费率"}],
                },
                saveLoad: false,
            };
        },
        created() {
            this.title = this.$route.query.id ? "编辑 产品" : "新增 产品";
            this.detailForm.id && this.getDetail();
        },
        methods: {
            limitThree() {//正整数且只能输入3位
                this.detailForm.defaultAmount =  this.detailForm.defaultAmount.replace(/^(0+)|[^\d]+/g,'')

            },
            limitFourPoint(e,type) {    //做多保留小数点后四位
                this.detailForm[type] = (e.match(/^\d*(\.?\d{0,4})/g)[0]) || null
            },

            //新增banner
            addProduct(form) {
                this.saveLoad = true;
                addProduct(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //新增banner
            updateProduct(form) {
                this.saveLoad = true;
                updateProduct(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getDetail() {
                this.pageLoad = true;
                getProductDetail({id: this.detailForm.id})
                    .then((res) => {
                        Object.assign(this.detailForm, res.data);
                        this.pageLoad = false;
                    })
                    .catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.updateProduct(this.detailForm)
                        } else {
                            this.addProduct(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/ProductList");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
