<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="120px"
                    label-suffix=":"
            >
                <el-form-item label="模块类型" prop="locationCode">
                    <el-select v-model="detailForm.locationCode" placeholder="请选择类型">
                        <el-option
                                v-for="item in locationList"
                                :key="item.enumCode"
                                :value="item.enumCode"
                                :label="item.enumName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="图片" prop="imageUrl">
                    <GUploadImg
                            v-model="detailForm.imageUrl"
                            :customSize="{ width: 358, height: 150 }"
                            :fileSize="300"
                            :entityNo="'banner'"
                    />
                    <p class="global-upload-placeholder">建议尺寸: 656px*300px </p>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input
                            v-model="detailForm.title"
                            placeholder="请输入标题"
                            :maxlength="30"
                            show-word-limit
                    />
                </el-form-item>
                <el-form-item label="展示时间类型">
                    <el-select v-model="detailForm.displayType" placeholder="请选择类型">
                        <el-option
                                v-for="item in displayTypeList"
                                :key="item.enumCode"
                                :value="item.enumCode"
                                :label="item.enumName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="展示时间" v-if="detailForm.displayType == 'fix'" prop="dateTimeVal">
                    <el-date-picker
                            v-model="detailForm.dateTimeVal"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            @change="changeTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="跳转类型">
                    <el-select v-model="detailForm.jumpType" placeholder="请选择类型">
                        <el-option
                                v-for="item in jumpTypeList"
                                :key="item.enumCode"
                                :value="item.enumCode"
                                :label="item.enumName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                        v-if="detailForm.jumpType == 'h5'"
                        label="跳转至"
                        prop="jumpPath"
                >
                    <el-input v-model="detailForm.jumpPath" placeholder="请输入H5链接"/>
                </el-form-item>
                <el-form-item
                        label="用户状态"
                >
                    <el-select v-model="detailForm.visibleUserGroup" multiple>
                        <el-option
                                v-for="item in visibleUserGroupList"
                                :key="item.enumCode"
                                :value="item.enumCode"
                                :label="item.enumName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="排序"
                >
                    <el-input v-model="detailForm.sort" placeholder="请输入H5链接"/>
                </el-form-item>
                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save"
                    >保 存
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import GUploadImg from "@/components/GUploadImg";
    import {
        getEnumByKey,
        addBanner,
        updateBanner,
        getBannerInfo
    } from "@/api/public";

    export default {
        components: {
            GUploadImg,
        },
        data() {
            return {
                pageLoad: false,
                title: "",

                detailForm: {
                    displayType: "",
                    endTime: "",
                    id: this.$route.query.id || null,
                    imageUrl: "",
                    jumpPath: "",
                    jumpType: "",
                    locationCode: "",
                    sort: 0,
                    startTime: "",
                    status: null,
                    title: "",
                    visibleUserGroup: [],
                    dateTimeVal: [],
                },
                detailRules: {
                    locationCode: [{required: true, message: "请选择模块类型"}],
                    imageUrl: [{required: true, message: "请上传图片"}],
                    title: [{required: true, message: "请输入标题"}],
                    dateTimeVal: [{required: true, message: "请选择展示时间"}],
                    jumpPath: [{required: true, message: "请输入H5链接"}],
                },
                locationList: [],
                displayTypeList: [],
                jumpTypeList: [],
                visibleUserGroupList: [],
                saveLoad: false,
            };
        },
        created() {
            this.init();
            this.title = this.$route.query.id ? "编辑 Banner" : "新增 Banner";
            this.detailForm.id && this.getDetail();
        },
        methods: {
            changeTime(time) {
                if (time && time.length) {
                    this.detailForm.startTime = time[0];
                    this.detailForm.endTime = time[1];
                } else {
                    this.detailForm.startTime = "";
                    this.detailForm.endTime = "";
                }
            },

            //新增banner
            addBanner(form) {
                this.saveLoad = true;
                addBanner(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //新增banner
            updateBanner(form) {
                this.saveLoad = true;
                updateBanner(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getDetail() {
                this.pageLoad = true;
                getBannerInfo({id: this.detailForm.id})
                    .then((res) => {
                        Object.assign(this.detailForm, res.data);
                        if (res.data.displayType == 'fix') {
                            this.detailForm.dateTimeVal = [res.data.startTime, res.data.endTime]
                        } else {
                            this.detailForm.dateTimeVal = []

                        }
                        this.detailForm.visibleUserGroup = res.data.visibleUserGroup.split(',');
                        this.pageLoad = false;
                    })
                    .catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        let form = {
                            displayType: this.detailForm.displayType,
                            endTime: this.detailForm.displayType == 'fix' ? this.detailForm.endTime : '',
                            id: this.detailForm.id,
                            imageUrl: this.detailForm.imageUrl,
                            jumpPath: this.detailForm.jumpPath,
                            jumpType: this.detailForm.jumpType,
                            locationCode: this.detailForm.locationCode,
                            sort: this.detailForm.sort,
                            startTime: this.detailForm.displayType == 'fix' ? this.detailForm.startTime : '',
                            status: this.detailForm.status,
                            title: this.detailForm.title,
                            visibleUserGroup: this.detailForm.visibleUserGroup.join(',')
                        };

                        if (this.detailForm.id) {
                            this.updateBanner(form)
                        } else {
                            this.addBanner(form)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/banner");
            },
            init() {
                getEnumByKey({
                    key: "BANNER_DISPLAY_TYPE",
                }).then((res) => {
                    this.displayTypeList = res.data.getEnumResponseList;
                });
                getEnumByKey({
                    key: "BANNER_JUMP_TYPE",
                }).then((res) => {
                    this.jumpTypeList = res.data.getEnumResponseList;
                });
                getEnumByKey({
                    key: "BANNER_LOCATION_CODE ",
                }).then((res) => {
                    this.locationList = res.data.getEnumResponseList;
                });
                getEnumByKey({
                    key: "BANNER_VISIBLE_USER_GROUP ",
                }).then((res) => {
                    this.visibleUserGroupList = res.data.getEnumResponseList;
                });
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
