<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">分账设置</div>
        <div class="global-detail-content" style="padding-left: 15px;">
            <el-card class="box-card">
                <div slot="header" class="clearfix" style="display: flex;align-items: flex-end;">
                    <p class="header_title">中金支付 - 分账配置</p>
                    <span>（分账比例）</span>
                </div>
                <el-form
                        ref="detailForm"
                        class="global-detail-content"
                        label-width="auto"
                        label-suffix=":"
                >
                    <el-form-item :label="item.companyName" v-for="(item,i) in configList" :key="i">
                        <el-input
                                v-model="item.ratio"
                                :placeholder="`请输入${item.companyName}分账比例`"
                                @input="(e)=>{regVal(e,i)}"
                        >
                            <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%</i>
                        </el-input>
                    </el-form-item>

                    <el-form-item class="global-detail-form-footer">
                        <!--<el-button @click="cancel">取 消</el-button>-->
                        <el-button type="primary" :loading="saveLoad" @click="updateSplitAccount">保 存</el-button>
                    </el-form-item>
                </el-form>
            </el-card>

        </div>
    </div>
</template>

<script>

    import {
        getSplitAccountInfo, updateSplitAccount
    } from "@/api/public";

    export default {
        data() {
            return {
                pageLoad: false,
                configList: [],
                saveLoad: false,
            };
        },
        created() {
            this.getDetail();
        },
        methods: {
            updateSplitAccount() {
                this.saveLoad = true;
                updateSplitAccount({splitAccountRequestList: this.configList}).then(() => {
                    this.getDetail();
                    this.saveLoad = false;
                    this.$message.success('保存成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            regVal(e, index) {
                if (e <= 100) {
                    this.configList[index].ratio = (e.match(/^\d*(\.?\d{0,4})/g)[0]) || null;
                    this.calcVal(index)
                } else {
                    this.configList[index].ratio = 100.0000;
                    this.calcVal(index)
                    this.$message.warning('输入比例不能超过100%')
                }
            },

            calcVal(index) {
                this.configList.forEach((item, i) => {
                    if (i != index) {
                        item.ratio = this.getFloat(100 - (this.configList[index].ratio * 1), 4)
                    }
                })
            },

            getFloat(number, n) {
                n = n ? parseInt(n) : 0;
                if (n <= 0) {
                    return Math.round(number);
                }
                number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
                number = Number(number).toFixed(n); //补足位数
                return number;
            },

            getDetail() {
                this.pageLoad = true;
                getSplitAccountInfo({})
                    .then((res) => {
                        this.configList = res.data;
                        this.pageLoad = false;
                    })
                    .catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        let form = {
                            displayType: this.detailForm.displayType,
                            endTime: this.detailForm.displayType == 'fix' ? this.detailForm.endTime : '',
                            id: this.detailForm.id,
                            imageUrl: this.detailForm.imageUrl,
                            jumpPath: this.detailForm.jumpPath,
                            jumpType: this.detailForm.jumpType,
                            locationCode: this.detailForm.locationCode,
                            sort: this.detailForm.sort,
                            startTime: this.detailForm.displayType == 'fix' ? this.detailForm.startTime : '',
                            status: this.detailForm.status,
                            title: this.detailForm.title,
                            visibleUserGroup: this.detailForm.visibleUserGroup.join(',')
                        };

                        if (this.detailForm.id) {
                            this.updateBanner(form)
                        } else {
                            this.addBanner(form)
                        }
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    .header_title {
        font-size: 14px;
        font-weight: 600;
        margin-right: 10px;
    }

    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
