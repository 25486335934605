<!--订单列表-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo" label-width="auto">
                <el-form-item label="借款编号">
                    <el-input
                            v-model="filterInfo.borrowOrderNo"
                            placeholder="借款编号"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="借款产品">
                    <el-select
                            v-model="filterInfo.productId"
                            placeholder="请选择借款产品"
                            clearable
                    >
                        <el-option label="全部" :value="null"/>
                        <el-option  v-for="(item,i) in productList" :key="i" :label="item.productName" :value="item.id"/>

                    </el-select>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input
                            v-model="filterInfo.userId"
                            placeholder="用户ID"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="渠道来源">
                    <el-select
                            v-model="filterInfo.sourceChannelCode"
                            placeholder="请选择渠道"
                            clearable
                    >
                        <el-option label="全部" :value="null"/>
                        <el-option
                                v-for="item in channelSource"
                                :key="item.channelCode"
                                :value="item.channelCode"
                                :label="item.channelName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="申请时间">
                    <DatePicker
                            v-model="filterInfo.time"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="changeTime"
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>

        <div class="global-view-table">
            <div class="table-header">
                <el-radio-group v-model="filterInfo.state" @change="changeTab">
                    <el-radio-button label="null">全部</el-radio-button>
                    <el-radio-button :label="item.enumCode" v-for="(item,i) in stateList" :key="i">{{item.enumName}}
                    </el-radio-button>

                </el-radio-group>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="borrowOrderNo" label="借款编号" width="240"/>
                <el-table-column prop="productName" label="借款产品" width="140"/>
                <el-table-column prop="amount" label="申请金额" width="120"/>
                <el-table-column prop="periods" label="借款期数" width="120">
                    <template slot-scope="{row}">
                        <span v-if="row.periods">{{row.periods}}期</span>
                    </template>
                </el-table-column>
                <el-table-column prop="notRepayAmount" label="待还金额" width="120"/>
                <el-table-column prop="userId" label="用户ID" width="120">
                    <template slot-scope="{row}">
                        <span style="cursor: pointer;color: #409EFF;" @click="toUser(row.userId)">{{row.userId}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sourceChannelCode" label="来源渠道" width="140">
                    <template slot-scope="{row}">
                        {{$enumChannelObj(row.sourceChannelCode,channelSource)}}
                    </template>
                </el-table-column>
                <el-table-column prop="borrowTime" label="申请时间" width="160"/>
                <el-table-column prop="state" label="状态" width="140">
                    <template slot-scope="{row}">
                        {{$enumArrayObj(row.state,stateList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" fixed="right" width="350">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">查看</el-button>
                        <el-button size="small" @click="toDetail(row)" v-if="row.state == 6 || row.state == 8"
                                   type="primary">申请平账
                        </el-button>
                        <el-button size="small" @click="getOrderRemitFailReason(row)" v-if="row.state == -1"
                                   type="info">失败原因
                        </el-button>
                        <el-button size="small" @click="repayOrder(row)" v-if="row.state == -1" type="warning">重新打款
                        </el-button>
                        <el-button size="small" @click="cancelApplyOrder(row)" v-if="row.state == -1" type="danger">取消订单
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>


        <el-dialog title="失败原因" :visible.sync="dialogFormVisible" center="">
            <div style="max-height: 500px;overflow-y: auto;">
                <!--<el-card style="margin-bottom: 10px;" shadow="never">-->
                <el-descriptions class="margin-top" :column="1">
                    <!--<el-descriptions-item label="打款编号">kooriookami</el-descriptions-item>-->
                    <!--<el-descriptions-item label="打款时间">18100000000</el-descriptions-item>-->
                    <el-descriptions-item label="失败原因">{{failReason}}</el-descriptions-item>
                </el-descriptions>
                <!--</el-card>-->
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogFormVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getEnumByKey,allChannelList,
        getOrderList, getOrderRemitFailReason, repayOrder, cancelApplyOrder, getProductList
    } from "@/api/public";
    import {filterFormat} from "@/utils/tools";
    import {DatePicker} from "element-ui";

    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
            DatePicker
        },
        data() {
            return {
                channelSource: [],
                filterInfo: {
                    borrowOrderNo: null,
                    productId: null,
                    endDate: null,
                    sourceChannelCode: null,
                    state: null,
                    userId: null,
                    startDate: null,
                    time: []
                },
                stateList: [],
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                dialogFormVisible: false,
                failReason: null,
                productList:[]
            };
        },
        created() {
            this.getProductList();
            this.init();
            this.search(this.pageInfo);
        },
        methods: {
            getProductList(){
                getProductList({
                    pageNum: 1,
                    pageSize: 999,
                }).then((res) => {
                    this.productList = res.data.records;
                })
            },

            cancelApplyOrder(row) {
                this.$messageBox.confirm('确认取消该笔订单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    cancelApplyOrder({id: row.id}).then(() => {
                        this.search(this.pageInfo)
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                    }).catch(() => {

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },

            repayOrder(row) {
                this.$messageBox.confirm('确认是否重新打款, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    repayOrder({id: row.id}).then(() => {
                        this.search(this.pageInfo)
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                    }).catch(() => {

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },

            getOrderRemitFailReason(row) {
                getOrderRemitFailReason({borrowOrderNo: row.borrowOrderNo}).then((res) => {
                    this.failReason = res.data.failReason;
                    this.dialogFormVisible = true;
                })
            },

            toUser(id) {
                this.$router.push({path: '/userDetail', query: {id: id}})
            },
            changeTab(val) {
                this.filterInfo.state = val;
                this.search({pageNum: 1, pageSize: this.pageInfo.pageSize})
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path: '/orderDetail', query: {id: row.id, borrowOrderNo: row.borrowOrderNo}})
            },

            init() {
                this.getEnumByKey('stateList', 'LOAN_ORDER_STATE')
                this.allChannelList()
            },
            allChannelList(){
                allChannelList({}).then((res)=>{
                    this.channelSource = res.data;
                })
            },

            // 获取枚举
            getEnumByKey(type, val) {
                getEnumByKey({key: val}).then((res) => {
                    this[type] = res.data.getEnumResponseList;
                })
            },
            changeTime(time) {
                if (time && time.length) {
                    this.filterInfo.startDate = `${time[0]} 00:00`;
                    this.filterInfo.endDate = `${time[1]} 23:59`;
                } else {
                    this.filterInfo.startDate = "";
                    this.filterInfo.endDate = "";
                }
            },

            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getOrderList({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
