<!--用户列表-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo" label-width="auto">
                <el-form-item label="用户ID">
                    <el-input
                            v-model="filterInfo.id"
                            placeholder="用户ID"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input
                            v-model="filterInfo.phone"
                            placeholder="手机号"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="注册日期">
                    <DatePicker
                            v-model="filterInfo.time"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="changeTime"
                    />
                </el-form-item>

                <el-form-item label="是否认证">
                    <el-select
                            v-model="filterInfo.authFlag"
                            placeholder="请选择是否认证"
                            clearable
                    >
                        <el-option label="全部" :value="null"/>
                        <el-option label="是" :value="true"/>
                        <el-option label="否" :value="false"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="渠道来源">
                    <el-select
                            v-model="filterInfo.sourceChannelCode"
                            placeholder="请选择渠道"
                            clearable
                    >
                        <el-option label="全部" :value="null"/>
                        <el-option
                                v-for="item in channelSource"
                                :key="item.channelCode"
                                :value="item.channelCode"
                                :label="item.channelName"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>

        <div class="global-view-table">
            <!--<div class="table-header">-->
            <!--<el-button class="right-operation" type="primary" @click="addMerchant"-->
            <!--&gt;创建账号-->
            <!--</el-button-->
            <!--&gt;-->
            <!--</div>-->

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="用户ID" width="80px"/>
                <el-table-column prop="phone" label="用户手机号"/>
                <el-table-column prop="sourceChannelCode" label="渠道来源">
                    <template slot-scope="{row}">
                        {{$enumChannelObj(row.sourceChannelCode,channelSource)}}
                    </template>
                </el-table-column>
                <el-table-column prop="sourceChannelUserId" label="外部编号"/>
                <el-table-column prop="createTime" label="注册时间"/>
                <el-table-column prop="operation" label="操作" width="80px">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        allChannelList,
        getBasicUserList,
    } from "@/api/public";
    import {filterFormat} from "@/utils/tools";
    import {DatePicker} from "element-ui";

    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
            DatePicker
        },
        data() {
            return {
                channelSource: [],
                filterInfo: {
                    authFlag: null,
                    sourceChannelCode: null,
                    endDate: null,
                    id: null,
                    nickName: null,
                    phone: null,
                    startDate: null,
                    time: []
                },

                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
            };
        },
        created() {
            this.init();
            this.search(this.pageInfo);
        },
        methods: {
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path: '/userDetail', query: {id: row.id}})
            },

            init() {
                this.allChannelList()
            },
            allChannelList(){
                allChannelList({}).then((res)=>{
                    this.channelSource = res.data;
                })
            },

            changeTime(time) {
                if (time && time.length) {
                    this.filterInfo.startDate = `${time[0]} 00:00`;
                    this.filterInfo.endDate = `${time[1]} 23:59`;
                } else {
                    this.filterInfo.startDate = "";
                    this.filterInfo.endDate = "";
                }
            },

            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getBasicUserList({
                    ...this.pageInfo,
                    ...this.filterInfo,
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
