<template>
    <div class="global-view-card">
        <div class="global-detail-head" style="display: flex;justify-content: space-between;align-items: center;">
            <span>查看明细</span>
            <div>
                <el-tag type="danger" v-if="status == 0">未对账</el-tag>
                <el-tag type="success" v-if="status == 1">已对账</el-tag>

                <el-button type="primary" style="margin-left: 10px;" v-if="status == 0" @click="doConfirm">对账无误
                </el-button>
            </div>
        </div>
        <div class="global-detail-content">
            <div class="diy_search">
                <el-form inline :model="filterInfo" label-width="auto">
                    <el-form-item label="借款编号">
                        <el-input v-model="filterInfo.borrowOrderNo" placeholder="请输入"/>
                    </el-form-item>
                    <el-form-item label="用户ID">
                        <el-input v-model="filterInfo.userId" placeholder="请输入"/>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button
                                :loading="tabType == 1 ? tableLoad:tableLoad2"
                                type="primary"
                                icon="el-icon-search"
                                @click="search()"
                        >搜 索
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>

            <div class="diy_content">
                <div class="table-header">
                    <div class="diy_date">
                        <el-radio-group v-model="tabType" @change="changeTab">
                            <el-radio-button label="1">放款记录</el-radio-button>
                            <el-radio-button label="2">还款记录</el-radio-button>
                        </el-radio-group>
                        <div class="diy_h4">日期：{{filterInfo.dateTime}}</div>
                    </div>
                    <el-button type="primary" v-loading="exportLoading"
                               @click="tabType == 1 ? exportLoanRecord():exportRepayRecord()">导出明细
                    </el-button>
                </div>

                <el-table v-loading="tableLoad" border :data="tableData" v-if="tabType == 1">
                    <el-table-column prop="borrowOrderNo" label="借款编号"/>
                    <el-table-column prop="sourceChannelCode" label="渠道来源">
                        <template slot-scope="{row}">
                            {{$enumChannelObj(row.sourceChannelCode,channelSource)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="periods" label="借款期数"/>
                    <el-table-column prop="userId" label="用户ID"/>
                    <el-table-column prop="remitOrderNo" label="支付系统订单号"/>
                    <el-table-column prop="channelType" label="支付通道">
                        <template slot-scope="{row}">
                            <span v-if="row.channelType == 'CPCN'">中金</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column prop="repaymentTotal" label="三方流水号"/>-->
                    <el-table-column prop="amount" label="放款金额（元）"/>
                    <el-table-column prop="realRemitTime" label="交易时间"/>
                </el-table>

                <GPagination
                        style="margin-top: 10px;"
                        v-if="tabType == 1"
                        :total="total"
                        :current-page="pageInfo.pageNum"
                        :size="pageInfo.pageSize"
                        @change="changePage"
                />

                <el-table v-loading="tableLoad2" border :data="tableData2" v-if="tabType == 2">
                    <el-table-column prop="borrowOrderNo" label="借款编号" width="180" fixed="left"/>
                    <el-table-column prop="sourceChannelCode" label="渠道来源">
                        <template slot-scope="{row}">
                            {{$enumChannelObj(row.sourceChannelCode,channelSource)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="periods" label="借款期数" width="80"/>
                    <el-table-column prop="userId" label="用户ID" width="80"/>
                    <el-table-column prop="repayOrderNo" label="支付系统订单号" width="220"/>
                    <el-table-column prop="channelType" label="支付通道" width="100">
                        <template slot-scope="{row}">
                            <span v-if="row.channelType == 'CPCN'">中金</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column prop="repaymentTotal" label="三方流水号"/>-->
                    <el-table-column prop="repayTotalAmount" label="还款金额（元）" width="120"/>
                    <el-table-column prop="notifyTime" label="交易时间" width="200"/>
                    <el-table-column prop="repaySplitOrderMc" label="每辰分账流水号" width="220"/>
                    <el-table-column prop="repaySplitAmountMc" label="每辰分得金额（元）" width="130"/>
                    <el-table-column prop="repaySplitOrderBl" label="保理分账流水号" width="220"/>
                    <el-table-column prop="repaySplitAmountBl" label="保理分得金额（元）" width="130"/>
                </el-table>

                <GPagination
                        style="margin-top: 10px;"
                        v-if="tabType == 2"
                        :total="total2"
                        :current-page="pageInfo2.pageNum"
                        :size="pageInfo2.pageSize"
                        @change="changePage2"
                />
            </div>

        </div>
    </div>
</template>

<script>
    import {
        allChannelList, getProductList, doConfirm, exportLoanRecord, exportRepayRecord, getLoanRecord, getRepayRecord
    } from "@/api/public";
    import GPagination from "@/components/GPagination";
    import {filterFormat} from "@/utils/tools";

    export default {
        components: {
            GPagination,
        },
        data() {
            return {
                exportLoading: false,
                filterInfo: {
                    borrowOrderNo: null,
                    productId: this.$route.query.productId || null,
                    userId: null,
                    dateTime: this.$route.query.createDay || null,
                },
                status: this.$route.query.status || null,
                id: this.$route.query.id || null,
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                tableLoad2: false,
                tableData2: [],
                total2: 0,
                pageInfo2: {
                    pageNum: 1,
                    pageSize: 20,
                },
                productList: [],
                tabType: 1,
                channelSource: []
            };
        },
        created() {
            this.getProductList();
            this.filterInfo.productId && this.filterInfo.dateTime ? this.getLoanRecord({
                pageNum: 1,
                pageSize: this.pageInfo.pageSize
            }) : ''
            this.allChannelList()
        },
        methods: {
            exportLoanRecord() {
                this.exportLoading = true;
                exportLoanRecord({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        window.location.href = res.data
                        this.exportLoading = false;
                    })
                    .catch(() => {
                        this.exportLoading = false;
                    });
            },

            exportRepayRecord() {
                this.exportLoading = true;
                exportRepayRecord({
                    ...this.pageInfo2,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        window.location.href = res.data
                        this.exportLoading = false;
                    })
                    .catch(() => {
                        this.exportLoading = false;
                    });
            },
            doConfirm() {
                this.$messageBox.confirm('确认是否对账无误, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    doConfirm({id: this.id}).then(() => {
                        this.id = null;
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                    }).catch(() => {

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },

            search() {
                if (this.tabType == 1) {
                    this.getLoanRecord({pageNum: 1, pageSize: this.pageInfo.pageSize});
                } else {
                    this.getRepayRecord({pageNum: 1, pageSize: this.pageInfo2.pageSize})
                }
            },

            // 获取枚举
            allChannelList(){
                allChannelList({}).then((res)=>{
                    this.channelSource = res.data;
                })
            },

            changeTab(val) {
                this.tabType = val;
                if (val == 1) {
                    this.getLoanRecord({pageNum: 1, pageSize: this.pageInfo.pageSize});
                } else {
                    this.getRepayRecord({pageNum: 1, pageSize: this.pageInfo2.pageSize})
                }
            },

            getProductList() {
                getProductList({
                    pageNum: 1,
                    pageSize: 999,
                }).then((res) => {
                    this.productList = res.data.records;
                })
            },
            changePage(page) {
                this.getLoanRecord(page);
            },
            changePage2(page) {
                this.getRepayRecord(page);
            },

            getLoanRecord(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getLoanRecord({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            },

            getRepayRecord(page) {
                this.pageInfo2 = page;
                this.tableLoad2 = true;
                getRepayRecord({
                    ...this.pageInfo2,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        this.tableData2 = res.data.records;
                        this.total2 = res.data.total;
                        this.tableLoad2 = false;
                    })
                    .catch(() => {
                        this.tableLoad2 = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .diy_search {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        margin-left: 16px;
        margin-bottom: 16px;
        padding: 16px 0 16px 16px;
        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }

    .diy_content {
        padding: 16px 0 16px 16px;
        width: 100%;

        .table-header {
            display: flex;
            justify-content: space-between;
        }

        .diy_date {
            height: 32px;
            display: flex;
            align-items: end;

            .diy_h4 {
                color: #000;
                font-size: 14px;
                font-weight: 600;
                margin-left: 10px;
            }
        }

    }
</style>
