<!--商户列表-->
<template>
  <div class="role-model">
    <div class="global-header">
      <el-form inline :model="filterInfo">
        <el-form-item label="角色名称">
          <el-input
            v-model="filterInfo.roleName"
            placeholder="角色名称"
            clearable
          />
        </el-form-item>

        <el-form-item label="">
          <el-button
            :loading="tableLoad"
            type="primary"
            icon="el-icon-search"
            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
            >搜 索</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <div class="table-header">
        <el-button class="right-operation" type="primary" @click="add"
          >创建角色</el-button
        >
      </div>

      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column type="index" label="ID" width="80px" />
        <el-table-column prop="roleName" label="角色名称" />
        <el-table-column prop="useByUserCount" label="权限人数" />
        <el-table-column prop="operation" label="操作" width="260px">
          <template slot-scope="{ row }">
            <el-button size="small" type="primary" @click="edit(row)">
              编辑
            </el-button>
            <el-button size="small" type="success" @click="setAuth(row)">
              设置权限
            </el-button>
            <el-button size="small" type="danger" @click="del(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
        :total="total"
        :current-page="pageInfo.pageNum"
        :size="pageInfo.pageSize"
        @change="changePage"
      />
    </div>

    <el-dialog
      :visible.sync="roleDialog.visible"
      custom-class="account-dialog"
      :title="roleDialog.title"
      width="600px"
      center
      @close="roleDialog.visible = false"
      @closed="cancelDialog"
    >
      <el-form
        ref="roleFormRef"
        :model="roleForm"
        :rules="roleRules"
        label-width="100px"
        label-suffix=":"
        size="large"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model="roleForm.roleName"
            :maxlength="12"
            placeholder="请输入角色名称"
          />
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="roleDialog.visible = false">取 消</el-button>
        <el-button :loading="dialogLoad" type="primary" @click="confirmSubmit">
          保 存
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="authDialog.visible"
      title="设置权限"
      class="auth-role-dialog"
      width="600px"
      center
      @close="authDialog.visible = false"
      @closed="cancelRole"
    >
      <Tree
        ref="authRoleRef"
        class="auth-role-tree"
        v-loading="treeLoad"
        :data="authTree"
        show-checkbox
        default-expand-all
        node-key="menuId"
        highlight-current
      />
      <span slot="footer">
        <el-button @click="authDialog.visible = false">取 消</el-button>
        <el-button :loading="dialogLoad" type="primary" @click="confirmAllot">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleList,
  saveRole,
  deleteRole,
  getRoleMenuList,
  setRoleMenus,
} from "@/api/public";
import { filterFormat } from "@/utils/tools";
import GPagination from "@/components/GPagination";
import { Tree } from "element-ui";
export default {
  name: "account-list",
  components: {
    GPagination,
    Tree,
  },
  data() {
    return {
      filterInfo: {
        roleName: null,
      },
      roleDialog: {
        visible: false,
        title: "",
      },
      roleForm: {
        roleName: "",
        roleId: "",
      },
      roleRules: {
        roleName: [{ required: true, message: "请输入角色名称" }],
      },
      authDialog: {
        roleId: "",
        visible: false,
      },
      dialogLoad: false,
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      treeLoad: false,
      authTree: [],
    };
  },
  created() {
    this.search(this.pageInfo);
  },
  methods: {
    add() {
      this.roleDialog = {
        visible: true,
        title: "新增角色",
      };
    },
    edit(row) {
      this.roleDialog = {
        visible: true,
        title: "编辑角色",
      };
      this.$nextTick(() => {
        this.roleForm = {
          roleId: row.roleId,
          roleName: row.roleName,
        };
      });
    },
    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getRoleList({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
        .then((res) => {
          const { total, records } = res.data;
          this.tableData = records;
          this.total = total;
          this.tableLoad = false;
        })
        .catch(() => {
          this.tableLoad = false;
        });
    },
    changePage(page) {
      this.search(page);
    },
    cancelDialog() {
      this.dialogLoad = false;
      this.roleDialog.title = "";
      this.roleForm.roleId = "";
      this.$refs.roleFormRef.resetFields();
    },
    // 提交商户
    confirmSubmit() {
      this.$refs.roleFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoad = true;
          saveRole(this.roleForm)
            .then(() => {
              this.$message.success("保存成功");
              this.roleDialog.visible = false;
              this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
            })
            .catch(() => {
              this.dialogLoad = false;
            });
        }
      });
    },
    del(row) {
      this.$messageBox
        .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          deleteRole({
            roleId: row.roleId,
          }).then(() => {
            this.$message.success("删除成功");
            this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    setAuth(row) {
      this.authDialog.roleId = row.roleId;
      this.authDialog.visible = true;
      this.treeLoad = true;
      getRoleMenuList({
        roleId: row.roleId,
      })
        .then((res) => {
          //TODO 待优化
          const { menus } = res.data;
          const parentIds = [];
          this.authTree = menus
            .filter((v) => v.parentId === 0)
            .map((v) => {
              const chldren = this.formatTree(v.menuId, menus, parentIds);
              if (chldren && chldren.length) {
                parentIds.push(v.menuId);
              }
              return {
                label: v.menuName,
                state: v.state,
                menuId: v.menuId,
                parentId: v.parentId,
                value: "",
                menuLevel: v.menuLevel,
                children: chldren,
              };
            });
          const menuIds = menus.filter((v) => v.checked).map((v) => v.menuId);
          parentIds.forEach((v) => {
            const index = menuIds.findIndex((l) => l === v);
            if (index > -1) {
              menuIds.splice(index, 1);
            }
          });
          this.$refs.authRoleRef.setCheckedKeys(menuIds);
          this.treeLoad = false;
        })
        .catch(() => {
          this.treeLoad = false;
        });
    },
    cancelRole() {
      this.dialogLoad = false;
      this.authDialog.roleId = "";
    },
    confirmAllot() {
      this.dialogLoad = true;
      const selectMenuIds = this.$refs.authRoleRef
        .getCheckedNodes(false, true)
        .map((v) => v.menuId);
      setRoleMenus({
        roleId: this.authDialog.roleId,
        selectMenuIds,
      })
        .then(() => {
          this.$message.success("保存成功");
          this.authDialog.visible = false;
          this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
        })
        .catch(() => {
          this.dialogLoad = false;
        });
    },
    formatTree(id, array, parentIds) {
      const childs = [];
      for (const arr of array) {
        if (arr.parentId === id) {
          childs.push({
            label: arr.menuName,
            state: arr.state,
            menuId: arr.menuId,
            parentId: arr.parentId,
            value: "",
            menuLevel: arr.menuLevel,
          });
        }
      }
      for (const child of childs) {
        const childscopy = this.formatTree(child.menuId, array);
        if (childscopy.length) {
          parentIds.push(child.menuId);
          child.children = childscopy;
        }
      }
      return childs;
    },
  },
};
</script>

<style lang="scss">
.role-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }
}
.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
.auth-role-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
.auth-role-tree {
  height: 500px;
  overflow-y: auto;
  padding-left: 16px;
  padding-top: 12px;
  .el-tree-node__content {
    height: 40px;
  }
}
</style>
