<template>
    <div class="global-view-card" v-loading="pageLoad">

        <!--<div class="global-detail-head">订单详情</div>-->
        <div class="sub_header" v-if="basicData.length > 0">
            <div class="sub_status">
                <p class="sub_label">当前状态:</p>
                <p class="sub_value" :style="{color:basicData[0].state == -1 && '#F56C6C'}">
                    {{$enumArrayObj(basicData[0].state,stateList)}}</p>
                <!--color:basicData[0].state == -1 ?'#F56C6C':(basicData[0].state == 8 ?'#E6A23C':(basicData[0].state == -2 ||basicData[0].state == -7 ?'#000000':'#67C23A' ))-->
            </div>
            <div class="sub_operation">
                <el-button type="danger" v-if="basicData[0].state == -1" @click="cancelApplyOrder">取消订单</el-button>
                <el-button type="warning" v-if="basicData[0].state == -1" @click="repayOrder">重新打款</el-button>
                <el-button type="primary" v-if="basicData[0].state == 6 || basicData[0].state == 8 ">申请平账</el-button>
            </div>
        </div>
        <div class="global-detail-content" style="padding-left: 10px">
            <el-card>
                <p class="content_title">基础信息</p>
                <el-table
                        v-loading="pageLoad"
                        :data="basicData"
                        border>
                    <el-table-column
                            prop="productName"
                            label="借款产品"
                            width="120"/>
                    <el-table-column
                            prop="amount"
                            label="借款金额" width="100"/>
                    <el-table-column
                            prop="periods"
                            label="借款期数" width="100">
                        <template slot-scope="{row}">
                            <span>{{row.periods}}期</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="remitAmount"
                            label="到账金额" width="100"/>
                    <el-table-column
                            prop="borrowTime"
                            label="申请时间" width="142"/>
                    <el-table-column
                            prop="riskTime"
                            label="风控审核时间" width="142"/>
                    <el-table-column
                            prop="realRemitTime"
                            label="放款时间"
                            width="142"/>
                    <el-table-column
                            prop="userId"
                            label="用户ID" width="80"/>
                    <el-table-column
                            prop="sourceChannelCode"
                            label="来源渠道" width="100">
                        <template slot-scope="{row}">
                            {{$enumChannelObj(row.sourceChannelCode,channelSource)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="useDesc"
                            label="借款用途"/>
                    <el-table-column
                            prop="overdueRate"
                            label="逾期利率" width="100"/>
                    <el-table-column
                            prop="overduePunishRate"
                            label="逾期费率" width="100"/>
                    <el-table-column
                            prop="state"
                            label="订单状态" width="100">
                        <template slot-scope="{row}">
                            {{$enumArrayObj(row.state,stateList)}}
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>


            <el-card style="margin-top: 10px;">
                <p class="content_title">还款计划</p>
                <el-table v-loading="planLoad" border :data="planData" size="mini">
                    <el-table-column prop="currentPeriod" label="还款计划"/>
                    <el-table-column prop="periodAmount" label="本期本金"/>
                    <el-table-column prop="periodInterest" label="本期利息"/>
                    <el-table-column prop="overdueAmount" label="本期罚息"/>
                    <el-table-column prop="shouldRepayAmount" label="本期应还"/>
                    <el-table-column prop="planDueTime" label="到期时间"/>
                    <el-table-column prop="overdueDays" label="逾期天数"/>
                    <el-table-column prop="periodState" label="本期还款状态">
                        <template slot-scope="{row}">
                            {{$enumArrayObj(row.periodState,repayStateList)}}
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-card style="margin-top: 10px;">
                <p class="content_title">还款记录</p>
                <el-table v-loading="replayLoad" border :data="replayData" size="mini">
                    <el-table-column prop="repayOrderNo" label="还款流水号"/>
                    <el-table-column prop="createTime" label="还款时间"/>
                    <el-table-column prop="repayAmount" label="本金"/>
                    <el-table-column prop="repayInterest" label="利息"/>
                    <el-table-column prop="repayOverdueAmount" label="罚息"/>
                    <el-table-column prop="repayTotalAmount" label="还款总额"/>
                    <el-table-column prop="repayStatus" label="还款状态">
                        <template slot-scope="{row}">
                            {{$enumArrayObj(row.repayStatus,repayRecordStateList)}}
                        </template>
                    </el-table-column>
                </el-table>

                <GPagination
                        style="float: right;padding: 10px 0;"
                        :total="total"
                        :current-page="pageInfo.pageNum"
                        :size="pageInfo.pageSize"
                        @change="getOrderReplayRecordList"
                />
            </el-card>

        </div>
    </div>
</template>

<script>
    import {
        getOrderBaseInfo, getOrderReplayPlanList, getOrderReplayRecordList, repayOrder, cancelApplyOrder, getEnumByKey,allChannelList
    } from "@/api/public";

    import GPagination from "@/components/GPagination";

    export default {
        components: {
            GPagination,
        },
        data() {
            return {
                pageLoad: false,
                pageId: this.$route.query.id || null,
                borrowOrderNo: this.$route.query.borrowOrderNo || null,
                basicData: [],
                channelSource: [],
                stateList: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                planData: [],
                planLoad: false,
                replayData: [],
                replayLoad: false,
                repayStateList:[],
                repayRecordStateList:[]
            };
        },
        created() {
            // this.init();
            this.pageId && this.init()
        },
        methods: {
            cancelApplyOrder() {
                this.$messageBox.confirm('确认取消该笔订单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    cancelApplyOrder({id: this.pageId}).then(() => {
                        this.getDetail()
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                    }).catch(() => {

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            repayOrder() {
                this.$messageBox.confirm('确认是否重新打款, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    repayOrder({id: this.pageId}).then(() => {
                        this.getDetail()
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                    }).catch(() => {

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });

            },

            init() {
                this.getEnumByKey('repayRecordStateList','RECORD_REPAY_STATUS');
                this.getEnumByKey('stateList', 'LOAN_ORDER_STATE');
                this.getEnumByKey('repayStateList','ORDER_REPAY_STATE')
                this.allChannelList()
                this.getDetail();
                this.getOrderReplayPlanList();
                this.getOrderReplayRecordList(this.pageInfo);
            },


            getDetail() {
                this.pageLoad = true;
                getOrderBaseInfo({id: this.pageId})
                    .then((res) => {
                        this.basicData = [res.data]
                        this.pageLoad = false;
                    }).catch(() => {
                    this.pageLoad = false;
                });
            },

            // 获取枚举
            getEnumByKey(type, val) {
                getEnumByKey({key: val}).then((res) => {
                    this[type] = res.data.getEnumResponseList;
                })
            },

            allChannelList(){
                allChannelList({}).then((res)=>{
                    this.channelSource = res.data;
                })
            },


            getOrderReplayPlanList() {
                this.planLoad = true;
                getOrderReplayPlanList({borrowOrderNo: this.borrowOrderNo}).then((res) => {
                    this.planData = res.data;
                    this.planLoad = false;
                }).catch(() => {
                    this.planLoad = false;

                })
            },

            getOrderReplayRecordList(page) {
                this.pageInfo = page;
                this.replayLoad = true;
                getOrderReplayRecordList({...this.pageInfo, borrowOrderNo: this.borrowOrderNo}).then((res) => {
                    this.total = res.data.total * 1;
                    this.replayData = res.data.records;
                    this.replayLoad = false;
                }).catch(() => {
                    this.replayLoad = false;

                })
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .content_title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 10px;
    }

    .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 10px 0;
    }

    .sub_header {
        display: flex;
        width: 100%;
        height: 80px;
        background: rgba(0, 0, 0, .3);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sub_status {
            display: flex;
            align-items: center;
            padding: 0 10px;

            .sub_label {
                font-size: 14px;
            }

            .sub_value {
                font-size: 18px;
                font-weight: 600;
                padding: 0 10px;
            }
        }

        .sub_operation {
            padding: 0 10px;
        }
    }

    .sub_content {
        display: flex;
        align-items: center;
        border: 1px solid #EBEEF5;
        background-color: #FFF;

        .sub_item {
            display: flex;
            width: 25%;
        }

        .sub_label {
            height: 100px;
            padding: 0 10px;
            font-weight: 600;
            font-size: 12px;
            color: #909399;
            background: #f5f7fa;
            line-height: 100px;
        }

        .sub_val {
            height: 100px;
            display: flex;
            align-items: center;
        }
    }


</style>
