<!--商户列表-->
<template>
  <div class="account-model">
    <div class="global-header">
      <el-form inline :model="filterInfo" label-width="auto">
        <el-form-item label="姓名">
          <el-input
            v-model="filterInfo.realName"
            placeholder="姓名"
            clearable
          />
        </el-form-item>
        <el-form-item label="账号">
          <el-input
            v-model="filterInfo.userMobile"
            placeholder="账号"
            clearable
          />
        </el-form-item>
        <el-form-item label="账号角色">
          <el-select
            v-model="filterInfo.roleId"
            placeholder="请选择角色"
            clearable
          >
            <el-option
              v-for="item in roleOption"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-button
            :loading="tableLoad"
            type="primary"
            icon="el-icon-search"
            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
            >搜 索</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <div class="table-header">
        <el-button class="right-operation" type="primary" @click="addMerchant"
          >创建账号</el-button
        >
      </div>

      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column type="index" label="ID" width="60px" />
        <el-table-column prop="loginName" label="账号" width="100" />
        <el-table-column prop="realName" label="姓名" width="120" />
        <el-table-column prop="roles" label="角色">
          <template slot-scope="{ row }">
            <el-tag
              class="role-tag"
              v-for="(role, index) in row.roles"
              :key="index"
            >
              {{ role.roleName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="loginTime" label="登录时间" width="140px" />
        <el-table-column prop="addTime" label="添加时间" width="140px" />
        <el-table-column prop="operatorState" label="状态" width="120px">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.operatorState"
              :active-value="1"
              :inactive-value="2"
              @change="changeStatus(row)"
            />
            <el-link
              :underline="false"
              :type="row.operatorState === 1 ? 'primary' : ''"
              class="status-label"
              >&nbsp;&nbsp;{{
                row.operatorState === 1 ? "启用" : "禁用"
              }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="240px">
          <template slot-scope="{ row }">
            <el-button size="small" type="primary" @click="edit(row)">
              编辑
            </el-button>
            <el-button size="small" type="success" @click="allotRole(row)">
              分配角色
            </el-button>
            <el-button size="small" type="danger" @click="del(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
        :total="total"
        :current-page="pageInfo.pageNum"
        :size="pageInfo.pageSize"
        @change="changePage"
      />
    </div>

    <el-dialog
      :visible.sync="accountDialog.visible"
      custom-class="account-dialog"
      :title="accountDialog.title"
      width="600px"
      center
      @close="accountDialog.visible = false"
      @closed="cancelDialog"
    >
      <el-form
        ref="accountFormRef"
        :model="accountForm"
        :rules="accountRules"
        label-width="90px"
        label-suffix=":"
        size="large"
      >
        <el-form-item label="账号" prop="loginName">
          <el-input
            v-model="accountForm.loginName"
            :maxlength="11"
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            placeholder="请输入账号"
          />
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input
            v-model="accountForm.realName"
            :maxlength="11"
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item
          label="登录密码"
          prop="pwdLogin"
          :rules="
            !accountForm.operatorId
              ? [{ required: true, message: '请输入登录密码' }]
              : [{ required: false }]
          "
        >
          <el-input
            v-model="accountForm.pwdLogin"
            :maxlength="32"
            type="password"
            placeholder="请输入登录密码"
          />
        </el-form-item>
        <el-form-item label="状态" prop="operatorState">
          <el-switch
            v-model="accountForm.operatorState"
            :active-value="1"
            :inactive-value="2"
          />
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="accountDialog.visible = false">取 消</el-button>
        <el-button :loading="dialogLoad" type="primary" @click="confirmSubmit">
          保 存
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="roleDialog.visible"
      title="分配角色"
      width="600px"
      center
      @close="roleDialog.visible = false"
      @closed="cancelRole"
    >
      <CheckboxGroup v-model="roleDialog.roleIds">
        <Checkbox
          v-for="item in roleOption"
          :key="item.roleId"
          :label="item.roleId"
        >
          {{ item.roleName }}
        </Checkbox>
      </CheckboxGroup>
      <span slot="footer">
        <el-button @click="roleDialog.visible = false">取 消</el-button>
        <el-button
          :loading="roleDialog.load"
          type="primary"
          @click="confirmAllotRole"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  getAllRoles,
  saveUser,
  deleteUser,
  setUserRoles,
} from "@/api/public";
import { filterFormat } from "@/utils/tools";
import { Checkbox, CheckboxGroup } from "element-ui";
import GPagination from "@/components/GPagination";
export default {
  name: "account-list",
  components: {
    GPagination,
    CheckboxGroup,
    Checkbox,
  },
  data() {
    return {
      filterInfo: {
        realName: null,
        userMobile: null,
        roleId: null,
      },
      accountDialog: {
        visible: false,
        title: "",
      },
      accountForm: {
        loginName: "",
        pwdLogin: "",
        realName: "",
        operatorState: 1,
        operatorId: "",
      },
      accountRules: {
        loginName: [{ required: true, message: "请输入账号" }],
        realName: [{ required: true, message: "请输入姓名" }],
      },
      dialogLoad: false,
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      roleOption: [],
      roleDialog: {
        operatorId: null,
        visible: false,
        roleIds: [],
        load: false,
      },
    };
  },
  created() {
    getAllRoles().then((res) => {
      this.roleOption = res.data.getMenusResponseList;
    });
    this.search(this.pageInfo);
  },
  methods: {
    addMerchant() {
      this.accountDialog = {
        visible: true,
        title: "新增账号",
      };
    },
    edit(row) {
      this.accountDialog = {
        visible: true,
        title: "编辑账号",
      };
      this.$nextTick(() => {
        this.accountForm = {
          loginName: row.loginName,
          pwdLogin: "",
          realName: row.realName,
          operatorState: row.operatorState,
          operatorId: row.operatorId,
        };
      });
    },
    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getUserList({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
        .then((res) => {
          const { total, records } = res.data;
          this.tableData = records;
          this.total = total;
          this.tableLoad = false;
        })
        .catch(() => {
          this.tableLoad = false;
        });
    },
    changePage(page) {
      this.search(page);
    },
    cancelDialog() {
      this.dialogLoad = false;
      this.accountDialog.title = "";
      this.accountForm.operatorId = "";
      this.$refs.accountFormRef.resetFields();
    },
    // 提交商户
    confirmSubmit() {
      this.$refs.accountFormRef.validate((valid) => {
        if (valid) {
          this.dialogLoad = true;
          saveUser({
            ...this.accountForm,
            pwdLogin: this.accountForm.pwdLogin || undefined,
          })
            .then(() => {
              this.$message.success("保存成功");
              this.accountDialog.visible = false;
              this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
            })
            .catch(() => {
              this.dialogLoad = false;
            });
        }
      });
    },
    changeStatus(row) {
      saveUser({
        loginName: row.loginName,
        operatorId: row.operatorId,
        operatorState: row.operatorState,
        realName: row.realName,
      }).then(() => {
        this.$message.success("保存成功");
      });
    },
    del(row) {
      this.$messageBox
        .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          deleteUser({
            loginName: row.loginName,
            operatorId: row.operatorId,
          }).then(() => {
            this.$message.success("删除成功");
            this.accountDialog.visible = false;
            this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    allotRole(row) {
      let roleIds = [];
      if (row.roles && row.roles.length) {
        roleIds = row.roles.map((v) => v.roleId);
      }
      this.roleDialog.operatorId = row.operatorId;
      this.roleDialog.roleIds = roleIds;
      this.roleDialog.visible = true;
    },
    cancelRole() {
      this.roleDialog.load = false;
      this.roleDialog.operatorId = null;
      this.roleDialog.roleIds = [];
    },
    confirmAllotRole() {
      this.roleDialog.load = true;
      setUserRoles({
        operatorId: this.roleDialog.operatorId,
        selectRoleIds: this.roleDialog.roleIds,
      })
        .then(() => {
          this.$message.success("保存成功");
          this.roleDialog.visible = false;
          this.search({ pageNum: 1, pageSize: this.pageInfo.pageSize });
        })
        .catch(() => {
          this.roleDialog.load = false;
        });
    },
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }
  .role-tag {
    margin-right: 8px;
  }
}
.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
</style>
