<template>
    <div class="global-view-card banner-model">
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="add"
                >创建Banner
                </el-button
                >
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="ID" width="80px"/>
                <el-table-column prop="imageUrl" label="图标">
                    <template slot-scope="{ row }">
                        <el-image
                                class="table-column-icon"
                                :src="row.imageUrl"
                                :preview-src-list="[row.imageUrl]">
                        </el-image>
                        <!--<img class="table-column-icon" :src="row.imageUrl" alt=""/>-->
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"/>
                <el-table-column prop="showTime" label="展示时间"/>
                <el-table-column prop="jumpType" label="跳转类型">
                    <template slot-scope="{ row }">
                        {{$enumArrayObj(row.jumpType,jumpTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="jumpPath" label="跳转参数" width="160" show-overflow-tooltip/>
                <el-table-column prop="visibleUserGroupDesc" label="用户状态">
                    <template slot-scope="{row}">
                       <el-tag v-for="(item,i) in row.visibleUserGroupDesc" :key="i" style="margin-right: 5px"> {{$enumArrayObj(item,userStateList)}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="70" sortable />
                <el-table-column prop="bannerStatus" label="状态" width="120px">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeStatus(row)"
                        />
                        <el-link
                                :underline="false"
                                :type="row.status === 1 ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status === 1 ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column
                        prop="operation"
                        label="操作"
                        width="150px"
                >
                    <template slot-scope="{ row }">
                        <el-button @click="edit(row)"> 编辑</el-button>
                        <el-button type="danger" @click="del(row)"> 删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {getBannerList, deleteBanner, changeBannerStatus, getEnumByKey} from "@/api/public";
    // import { filterFormat } from "@/utils/tools";
    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                filterInfo: {
                    roleName: null,
                },
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                bannerMap: {
                    1: "H5",
                    2: "广告",
                    3: "不跳转",
                },
                userStateList: [],
                jumpTypeList: []
            };
        },
        created() {
            this.getEnumByKey('jumpTypeList', 'BANNER_JUMP_TYPE ');
            this.getEnumByKey('userStateList', 'BANNER_VISIBLE_USER_GROUP ');
        },
        mounted() {
            this.search(this.pageInfo);
        },
        methods: {
            // 获取枚举
            getEnumByKey(type, val) {
                getEnumByKey({key: val}).then((res) => {

                    this[type] = res.data.getEnumResponseList;
                    console.log(this[type]);
                })
            },

            add() {
                this.$router.push("/bannerDetail");
            },
            edit(row) {
                this.$router.push(`bannerDetail?id=${row.id}`);
            },
            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getBannerList({
                    ...this.pageInfo,
                    // ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        records.forEach(item => {
                            item.visibleUserGroupDesc = item.visibleUserGroup && item.visibleUserGroup.split(',') || []
                        })
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            },
            changePage(page) {
                this.search(page);
            },
            cancelDialog() {
                this.dialogLoad = false;
                this.roleDialog.title = "";
                this.roleForm.roleId = "";
                this.$refs.roleFormRef.resetFields();
            },
            changeStatus(row) {
                changeBannerStatus({
                    id: row.id,
                    status: row.status,
                }).then(() => {
                    this.$message.success("修改成功");
                });
            },
            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        deleteBanner({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
    .banner-model {
        .global-view-table {
            margin: 0;
        }
        .table-column-icon {
            width: 72px;
            height: 20px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
</style>
