<template>
  <div class="global-view-card">
    <!--<div class="global-header">-->
      <!--<el-form inline :model="filterInfo">-->
        <!--<el-form-item label="广告编号">-->
          <!--<el-input-->
            <!--v-model="filterInfo.advertNo"-->
            <!--placeholder="广告编号"-->
            <!--clearable-->
          <!--/>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="机构名称">-->
          <!--<el-input-->
            <!--v-model="filterInfo.institutionName"-->
            <!--placeholder="机构名称"-->
            <!--clearable-->
          <!--/>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="机构名称">-->
          <!--<el-input-->
            <!--v-model="filterInfo.institutionName"-->
            <!--placeholder="机构名称"-->
            <!--clearable-->
          <!--/>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="日期">-->
          <!--<DatePicker-->
            <!--v-model="filterInfo.time"-->
            <!--type="daterange"-->
            <!--range-separator="至"-->
            <!--start-placeholder="开始日期"-->
            <!--end-placeholder="结束日期"-->
            <!--format="yyyy-MM-dd"-->
            <!--value-format="yyyy-MM-dd"-->
            <!--@change="changeTime"-->
          <!--/>-->
        <!--</el-form-item>-->

        <!--<el-form-item label="">-->
          <!--<el-button-->
            <!--:loading="tableLoad"-->
            <!--type="primary"-->
            <!--icon="el-icon-search"-->
            <!--@click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"-->
            <!--&gt;搜 索</el-button-->
          <!--&gt;-->
        <!--</el-form-item>-->
      <!--</el-form>-->
    <!--</div>-->

    <div class="global-view-table">
      <!--<el-table v-loading="tableLoad" border :data="tableData">-->
        <!--<el-table-column prop="advertNo" label="广告编号" />-->
        <!--<el-table-column prop="advertName" label="广告名称" />-->
        <!--<el-table-column prop="institutionName" label="机构名称" />-->
        <!--<el-table-column prop="liquidationTypeName" label="结算类型" />-->
        <!--<el-table-column prop="cpa" label="CPA（点击）" />-->
        <!--<el-table-column prop="uv" label="UV（人数）" />-->
      <!--</el-table>-->

      <!--<GPagination-->
        <!--:total="total"-->
        <!--:current-page="pageInfo.pageNum"-->
        <!--:size="pageInfo.pageSize"-->
        <!--@change="changePage"-->
      <!--/>-->
    </div>
  </div>
</template>

<script>
import { getAdvertStatisticsList, getEnumByKey } from "@/api/public";
import { filterFormat } from "@/utils/tools";
import GPagination from "@/components/GPagination";
import { DatePicker } from "element-ui";
export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker,
  },
  data() {
    return {
      filterInfo: {
        advertNo: null,
        institutionName: null,
      },
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        time: [],
        startTime: null,
        endTime: null,
      },
      liquidationTypeOption: new Map(),
    };
  },
  async created() {
    // const optionData = await getEnumByKey({
    //   key: "ADVERT_LIQUIDATION_TYPE",
    // });
    // optionData.data.getEnumResponseList.forEach((v) => {
    //   this.liquidationTypeOption.set(v.enumCode, v.enumName);
    // });
    // this.search(this.pageInfo);
  },
  methods: {
    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      // getAdvertStatisticsList({
      //   ...this.pageInfo,
      //   ...filterFormat(this.filterInfo),
      // })
      //   .then((res) => {
      //     const { total, records } = res.data;
      //     if (records && records.length) {
      //       records.forEach((v) => {
      //         v.liquidationTypeName = this.liquidationTypeOption.get(
      //           v.liquidationType
      //         );
      //       });
      //     }
      //     this.tableData = records;
      //     this.total = total;
      //     this.tableLoad = false;
      //   })
      //   .catch(() => {
      //     this.tableLoad = false;
      //   });
    },
    changePage(page) {
      this.search(page);
    },
    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startTime = `${time[0]} 00:00`;
        this.filterInfo.endTime = `${time[1]} 23:59`;
      } else {
        this.filterInfo.startTime = "";
        this.filterInfo.endTime = "";
      }
    },
  },
};
</script>

<style lang="scss">
</style>
